@tailwind base;
@tailwind components;
@tailwind utilities;


.shim-blue {
  position: relative;
  overflow: hidden;
  background-color: #eff6ff ;
}
.shim-blue::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    #eff6ff,
    #3b82f6 50%,
    #eff6ff
  );
  animation: shimmer 1.5s ease-out infinite;
  content: "";
}

@keyframes shimmer {
  100% {
    transform: translateX(0%);
    opacity: 0.8;
  }
}